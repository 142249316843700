import { render, staticRenderFns } from "./FormMedicalCompanies.vue?vue&type=template&id=77bb1185&scoped=true&"
import script from "./FormMedicalCompanies.vue?vue&type=script&lang=js&"
export * from "./FormMedicalCompanies.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77bb1185",
  null
  
)

export default component.exports